import { render, staticRenderFns } from "./RecordOverview.vue?vue&type=template&id=270e6aa9&scoped=true&"
import script from "./RecordOverview.vue?vue&type=script&setup=true&lang=ts&"
export * from "./RecordOverview.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./RecordOverview.vue?vue&type=style&index=0&id=270e6aa9&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "270e6aa9",
  null
  
)

export default component.exports